<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12 offset-lg-2">
                <div class="blog-details-header">
                    <h3>Band Members</h3>
                </div>
                <div class="blog-details-desc">
                    <div class="article-content">
                        <h3>Jay Freeman (Guitar)</h3>
                        <p>Jay was born in Houston Texas and spent his formative years there. He spent much of the early seventies touring throughout the south and Midwest, along the way sharing the stage with acts like ZZ Top and Ted Nugent. Jay left full time music to pursue a career in banking. Through his connections, Jay represented Wells Fargo at the Fortune Magazine Battle of the Corporate Bands, first at the Rock and Roll Hall of Fame in Cleveland and the following year in Nashville. On the second occasion, the headliner was Cheap Trick.</p>
                        <h3>Steve Miklos (Guitar)</h3>
                        <p>Steve was born and raised in Southern California. He and his brother started performing with their first band in the 8th grade and continued performing for many, many years. Their band played at outdoor music festivals, large corporate events and numerous private functions. His talents were mentored by his godfather, as well as his uncle who was a longtime headliner in Las Vegas. Their musical influence and 70’s genre stemmed from these two gentlemen.</p>
                        <h3>Bob Rogers (Guitar and Bass)</h3>
                        <p>Bob was raised in Alaska and moved to Folsom in 1975 where he graduated from the original Folsom High School. Bob has been playing guitar for many years and his natural talents and musical ear are a perfect fit for the band. He recently has expanded his talents to include the bass guitar.</p>
                        <h3>Scott Blakeslee (Drums)</h3>
                        <p>Scott was born and raised in New Jersey before relocating to CA in 1979. His drum journey began at age 8 after seeing the Beatles and Stones on the Ed Sullivan Show. Scott's percussion education includes school concert and marching band, as well as his garage rock band which won a NJ teen arts festival music award.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>