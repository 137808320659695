<section id="news" class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>About us</h2>
            <!-- <p>Our news</p> -->
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <!-- <div class="post-image">
                        <img src="assets/img/blog/img1.jpg" alt="image">
                        <a routerLink="/blog-details" class="link-btn"></a>
                    </div> -->

                    <div class="post-content">
                        <h3><a routerLink="/blog-details">Granite City 909</a></h3>
                        <p>Our band is called Granite City 909. The name refers to the City of Folsom, once known in it’s past as Granite City. And 909, the address of the historical elementary school in Folsom where the band played together during its formative period.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <!-- <div class="post-image">
                        <img src="assets/img/blog/img2.jpg" alt="image">
                        <a routerLink="/blog-details" class="link-btn"></a>
                    </div> -->

                    <div class="post-content">
                        <h3><a routerLink="/blog-details">GS 909 Sound</a></h3>
                        <p>Our roots are in the music of the ’70’s in music scenes as diverse as LA, Lake Tahoe/Reno and South Texas. Our sound captures the spirit of the age many considered to be rocks golden era. The bands professional and playing experience spans over a combined 125 years.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-blog-post">
                    <!-- <div class="post-image">
                        <img src="assets/img/blog/img3.jpg" alt="image">
                        <a routerLink="/blog-details" class="link-btn"></a>
                    </div> -->

                    <div class="post-content">
                        <h3><a routerLink="/blog-details">Granite City</a></h3>
                        <p>Granite City was the name of the City of Folsom before it was named Folsom. We wanted to honor out past and heritage, as all four of us live in the City of Folsom. Also, we are honoring the 1920’s vintage elementary school that allowed us to come together as a band and recreate great music for so many people.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>