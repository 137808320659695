<section id="gallery" class="gallery-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Photo Gallery</h2>
            <p></p>
        </div>

        <div class="gallery-slides owl-carousel owl-theme">
            <div class="single-gallery-item">
                <img src="assets/img/img1.jpg" alt="image">
                <a href="assets/img/gallery/img1.jpg" class="link-btn popup-btn"></a>
            </div>
            <div class="single-gallery-item">
                <img src="assets/img/img2.jpg" alt="image">
                <a href="assets/img/img2.jpg" class="link-btn popup-btn"></a>
            </div>
            <div class="single-gallery-item">
                <img src="assets/img/img3.jpg" alt="image">
                <a href="assets/img/img3.jpg" class="link-btn popup-btn"></a>
            </div>
            <div class="single-gallery-item">
                <img src="assets/img/img4.jpg" alt="image">
                <a href="assets/img/img4.jpg" class="link-btn popup-btn"></a>
            </div>
            <div class="single-gallery-item">
                <img src="assets/img/img5.jpg" alt="image">
                <a href="assets/img/img5.jpg" class="link-btn popup-btn"></a>
            </div>
            <div class="single-gallery-item">
                <img src="assets/img/img6.jpg" alt="image">
                <a href="assets/img/img6.jpg" class="link-btn popup-btn"></a>
            </div>
            <div class="single-gallery-item">
                <img src="assets/img/img7.jpg" alt="image">
                <a href="assets/img/img7.jpg" class="link-btn popup-btn"></a>
            </div>
        </div>
    </div>

    <!-- <div class="gallery-shape1"><img src="assets/img/player2.png" alt="image"></div> -->
</section>