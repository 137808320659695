<div id="home" class="main-banner">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="main-banner-content">
                    <span class="sub-title">Granite City</span>
                    <h1 style="color:#970014;">909</h1>
                </div>
            </div>
        </div>
    </div>

    <div class="banner-footer-content">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <a href="mailto:jay@granitecity909.com" class="email-link">Jay Freeman</a> <br>
                    <a href="mailto:steve@granitecity909.com" class="email-link">Steve Miklos</a> <br>
                    <a href="mailto:bob@granitecity909.com" class="email-link">Bob Rogers</a> <br>
                    <a href="mailto:scott@granitecity909.com" class="email-link">Scott Blakeslee</a> <br>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <ul class="social">
                        <!-- <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li> -->
                        <!-- <li><a href="#" target="_blank"><i class="flaticon-twitter-1"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-instagram-1"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-linkedin-1"></i></a></li> -->
                        <!-- <li><a href="#" target="_blank"><i class="flaticon-youtube-1"></i></a></li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>