<app-main-banner></app-main-banner>

<app-next-match></app-next-match>

<app-upcoming-matches></app-upcoming-matches>

<app-matches-highlights></app-matches-highlights>

<app-gallery></app-gallery>

<app-blog></app-blog>