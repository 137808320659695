<section id="matches" class="upcoming-matches-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Upcoming Concerts</h2>
        </div>

        <div class="upcoming-matches-slides owl-carousel owl-theme">
            <div class="single-upcoming-matches-item">
                <div class="date">
                    <span>15 June, 2024</span>
                </div>
                <h3>Holaloha - 9580 Oak Ave Pkwy</h3>
                <div class="vs-matches">
                    <h4>6:00PM - 9:00PM</h4>
                </div>
                <a href="#" class="default-btn">Purchase tickets at entrance</a>
            </div>
            <div class="single-upcoming-matches-item">
                <div class="date">
                    <span>21 June, 2024</span>
                </div>
                <h3>Folsom Palladio Outdoor Stage</h3>
                <div class="vs-matches">
                    <h4>7:30PM - 9:30PM</h4>
                </div>
                <a href="#" class="default-btn">Purchase tickets at entrance</a>
            </div>
            <div class="single-upcoming-matches-item">
                <div class="date">
                    <span>12 September, 2024</span>
                </div>
                <h3>Amphitheater</h3>
                <div class="vs-matches">
                    <h4>7:00PM - 9:00PM</h4>
                </div>
                <a href="#" class="default-btn">Purchase tickets at entrance</a>
            </div>

            <div class="single-upcoming-matches-item">
                <div class="date">
                    <span>02 November, 2024</span>
                </div>
                <h3>Powerhouse Pub</h3>
                <div class="vs-matches">
                    <h4>3:00PM - 6:00PM</h4>
                </div>
                <a href="#" class="default-btn">Purchase tickets at entrance</a>
            </div>

        </div>
    </div>

    <div class="upcoming-matches-shape1"><img style="max-width: 40%;" src="../../../../assets/img/guitar.jpg" alt="image"></div>
</section>