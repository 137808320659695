import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { NavbarComponent } from './components/layouts/navbar/navbar.component';
import { HomeComponent } from './components/pages/home/home.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { BlogComponent } from './components/layouts/blog/blog.component';
import { GalleryComponent } from './components/layouts/gallery/gallery.component';
import { MatchesHighlightsComponent } from './components/layouts/matches-highlights/matches-highlights.component';
import { UpcomingMatchesComponent } from './components/layouts/upcoming-matches/upcoming-matches.component';
import { NextMatchComponent } from './components/layouts/next-match/next-match.component';
import { MainBannerComponent } from './components/layouts/main-banner/main-banner.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    NavbarComponent,
    HomeComponent,
    BlogDetailsComponent,
    BlogComponent,
    GalleryComponent,
    MatchesHighlightsComponent,
    UpcomingMatchesComponent,
    NextMatchComponent,
    MainBannerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
