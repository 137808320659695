<section id="highlights" class="matches-highlights-slides owl-carousel owl-theme">
    <div class="single-matches-highlights-item highlights-bg1">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="content">
                        <h3>Concert Highlights</h3>
                        <h4>Joe Bonamassa - Going Down</h4>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="highlights-video">
                        <a href="assets/videos/Going-Down-PH.mp4" class="video-btn popup-youtube">
                            <span>Play Video</span>
                            <i class="flaticon-play-button"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="single-matches-highlights-item highlights-bg2">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="content">
                        <h3>Concert Highlights</h3>
                        <h4>Moody Blues - Story In Your Eyes</h4>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="highlights-video">
                        <a href="assets/videos/IMG_5142.mp4" class="video-btn popup-youtube">
                            <span>Play Video</span>
                            <i class="flaticon-play-button"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="single-matches-highlights-item highlights-bg3">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="content">
                        <h3>Concert Highlights</h3>
                        <h4>The Rolling Stones - Satisfaction</h4>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="highlights-video">
                        <a href="assets/videos/IMG_7042.mp4" class="video-btn popup-youtube">
                            <span>Play Video</span>
                            <i class="flaticon-play-button"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="single-matches-highlights-item highlights-bg4">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="content">
                        <h3>Concert Highlights</h3>
                        <h4>SS Top - Just Got Paid</h4>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="highlights-video">
                        <a href="assets/videos/Just-Got-Paid-PH.mp4" class="video-btn popup-youtube">
                            <span>Play Video</span>
                            <i class="flaticon-play-button"></i>
                        </a>
                    </div>
                </div>
            </div>
            <!-- <div class="subscribe-shape1"><img src="assets/img/football1.png" alt="image"></div> -->
        </div>
    </div>
</section>