<section class="next-match-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="next-match-content">
                    <div class="content">
                        <div class="row align-items-center">
                            <div class="col-lg-5 col-md-5">
                                <h2>Next Concert</h2>
                                <span class="sub-title">Holaloha - 9580 Oak Ave Pkwy - 15 June, 2024</span>
                            </div>
                            <div class="col-lg-7 col-md-7">
                                <div id="timer" class="flex-wrap d-flex justify-content-center">
                                    <div id="days" class="align-items-center flex-column d-flex justify-content-center"></div>
                                    <div id="hours" class="align-items-center flex-column d-flex justify-content-center"></div>
                                    <div id="minutes" class="align-items-center flex-column d-flex justify-content-center"></div>
                                    <div id="seconds" class="align-items-center flex-column d-flex justify-content-center"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="shape1"><img src="../../../../assets/img/guitar.jpg" alt="image"></div> -->
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div>
                    <img style="max-width: 100%;"src="../../../../assets/img/powerhouse_pub.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>